//时间戳转年月日  参数是秒的时间戳 函数返回一个对象 对象里有年 月 日
function yearDay(long) {
    var time = new Date(long * 1000)
    var year = time.getFullYear();
    var month = (time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : (time.getMonth() + 1);
    var date = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
    var yearday = { year, month, date }
    return yearday
}
//计算一年中的每一周都是从几号到几号
//第一周为1月1日到 本年的 第一个周日
//第二周为 本年的 第一个周一 往后推到周日
//以此类推 再往后推52周。。。
//如果最后一周在12月31日之前，则本年有垮了54周，反之53周
//12月31 日不论是周几，都算为本周的最后一天
//参数年份 ，函数返回一个数组，数组里的对象包含 这一周的开始日期和结束日期
export function whichWeek(year) {
    var d = new Date(year, 0, 1);
    while (d.getDay() != 1) {
        d.setDate(d.getDate() + 1);
    }
    let arr = []
    let longnum = d.setDate(d.getDate())
    if (longnum > +new Date(year, 0, 1)) {
        let obj = yearDay(+new Date(year, 0, 1) / 1000)
        obj.last = yearDay(longnum / 1000 - 86400)
        arr.push(obj)
    }
    let oneitem = yearDay(longnum / 1000)
    oneitem.last = yearDay(longnum / 1000 + 86400 * 6)
    arr.push(oneitem)
    var lastStr
    for (var i = 0; i < 51; i++) {
        let long = d.setDate(d.getDate() + 7)
        let obj = yearDay(long / 1000)
        obj.last = yearDay(long / 1000 + 86400 * 6)
        lastStr = long + 86400000 * 6
        arr.push(obj)
    }
    if (lastStr < +new Date(year + 1, 0, 1)) {
        let obj = yearDay(lastStr / 1000 + 86400)
        obj.last = yearDay(+new Date(year + 1, 0, 1) / 1000 - 86400)
        arr.push(obj)
    } else {
        arr[arr.length - 1].last = yearDay(+new Date(year + 1, 0, 1) / 1000 - 86400)
    }
    return arr
}

export function computeTime(time) {
    var date = new Date(time);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    var h = date.getHours();
    h = h < 10 ? "0" + h : h;
    var minute = date.getMinutes();
    minute = minute < 10 ? "0" + minute : minute;
    var s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + s;
}
export function DataTime(time) {
    var date = new Date(time);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    var h = date.getHours();
    h = h < 10 ? "0" + h : h;
    var minute = date.getMinutes();
    minute = minute < 10 ? "0" + minute : minute;
    var s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + "-" + m + "-" + d;
}

export function getLastDay() {
    console.log(123465)

    var y = new Date().getFullYear(); //获取年份
    var m = new Date().getMonth() + 1; //获取月份
    var d = '01'
    m = m < 10 ? '0' + m : m; //月份补 0
    return [y, m, d].join("-")
}