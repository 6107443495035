var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"toolbar"},[_c('el-input',{attrs:{"placeholder":"请输入手机号","size":"medium","clearable":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","size":"medium","icon":"el-icon-search"},on:{"click":_vm.search}},[_vm._v("查询")]),_c('el-button',{attrs:{"size":"medium"},on:{"click":_vm.reset}},[_vm._v("重置")]),_c('div',{staticClass:"right"},[_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":_vm.btnadd}},[_vm._v("新增")])],1)],1),_c('div',{staticClass:"divTb"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"header-cell-style":{
        background: '#cce1f9',
        color: '#505050',
        textAlign: 'center',
      },"cell-style":{ textAlign: 'center' }}},[_c('el-table-column',{attrs:{"prop":"userId","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"用户昵称"}}),_c('el-table-column',{attrs:{"prop":"phone","label":"用户手机号"}}),_c('el-table-column',{attrs:{"prop":"limitNumber","label":"待支付快递(件)"}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"添加用户时间"}}),_c('el-table-column',{attrs:{"label":"操作","width":"250px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"warning"},on:{"click":function($event){return _vm.edit(scope.row)}}},[_vm._v("编辑")]),_c('el-button',{staticStyle:{"margin-right":"5px"},attrs:{"size":"mini","type":"warning"},on:{"click":function($event){return _vm.deletes(scope.row)}}},[_vm._v("删除")]),_c('el-switch',{attrs:{"active-value":true,"inactive-value":false,"active-color":"#13ce66","inactive-color":"#ff4949"},on:{"change":(val) => {
                _vm.elswitch(val, scope.row);
              }},model:{value:(scope.row.isStart),callback:function ($$v) {_vm.$set(scope.row, "isStart", $$v)},expression:"scope.row.isStart"}}),(scope.row.isStart == 'true')?_c('span',{staticClass:"spanbtn"},[_vm._v("开")]):_vm._e(),(scope.row.isStart == 'false')?_c('span',{staticClass:"spanbtn"},[_vm._v("关")]):_vm._e()]}}])})],1),_c('el-pagination',{attrs:{"background":"","current-page":_vm.currentPage,"page-size":_vm.pageSize,"page-sizes":[5, 10, 15, 20, 50],"layout":"total, prev, pager, next,sizes","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('el-dialog',{attrs:{"title":"添加白名单用户","visible":_vm.dialog,"width":"30%"},on:{"update:visible":function($event){_vm.dialog=$event},"close":_vm.closeDialog}},[_c('el-form',{ref:"form",attrs:{"rules":_vm.rules,"model":_vm.form,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"用户名称","prop":"name"}},[_c('el-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),(_vm.Dotype == 1)?_c('el-form-item',{attrs:{"label":"用户手机号","prop":"phone"}},[_c('el-input',{model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"最大寄件数","prop":"num"}},[_c('el-input',{attrs:{"type":"number"},model:{value:(_vm.form.num),callback:function ($$v) {_vm.$set(_vm.form, "num", $$v)},expression:"form.num"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("取消")]),_c('el-button',{on:{"click":_vm.addcoll}},[_vm._v("确定")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }