<template>
  <div v-loading="loading">
    <!-- 搜索 -->
    <div class="toolbar">
      <el-input
        class=""
        placeholder="请输入手机号"
        v-model="phone"
        size="medium"
        clearable
      >
      </el-input>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-search"
        style="margin-left: 10px"
        @click="search"
        >查询</el-button
      >
      <el-button size="medium" @click="reset">重置</el-button>
      <div class="right">
        <el-button type="primary" size="medium" @click="btnadd">新增</el-button>
      </div>
    </div>
    <!-- 表格数据 -->

    <div class="divTb">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: '#cce1f9',
          color: '#505050',
          textAlign: 'center',
        }"
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
      >
        <el-table-column prop="userId" label="ID"> </el-table-column>
        <el-table-column prop="userName" label="用户昵称"> </el-table-column>
        <el-table-column prop="phone" label="用户手机号"> </el-table-column>
        <el-table-column prop="limitNumber" label="待支付快递(件)">
        </el-table-column>
        <el-table-column prop="createTime" label="添加用户时间">
        </el-table-column>
        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <el-button size="mini" type="warning" @click="edit(scope.row)"
              >编辑</el-button
            >

            <el-button
              style="margin-right: 5px"
              size="mini"
              type="warning"
              @click="deletes(scope.row)"
              >删除</el-button
            >

            <el-switch
              v-model="scope.row.isStart"
              @change="
                (val) => {
                  elswitch(val, scope.row);
                }
              "
              :active-value="true"
              :inactive-value="false"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
            <span class="spanbtn" v-if="scope.row.isStart == 'true'">开</span>
            <span class="spanbtn" v-if="scope.row.isStart == 'false'">关</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[5, 10, 15, 20, 50]"
        layout="total, prev, pager, next,sizes"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="添加白名单用户"
      :visible.sync="dialog"
      @close="closeDialog"
      width="30%"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="100px">
        <el-form-item label="用户名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item v-if="Dotype == 1" label="用户手机号" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="最大寄件数" prop="num">
          <el-input type="number" v-model="form.num"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="dialog = false">取消</el-button>
          <el-button @click="addcoll">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetUserWhiteListPage,
  AddUserWhiteList,
  UpWhiteList,
} from "@/api/coll";
import { computeTime } from "@/utils/computing-time";
export default {
  data() {
    return {
      loading: false,
      phone: "",
      form: {
        name: "",
        phone: "",
        num: "",
      },
      Dotype: 0,
      whiteid: "",
      tableData: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
      dialog: false,
      rules: {
        name: [{ required: true, message: "请输入用户名称", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^1[34578]\d{9}$/.test(value) == false) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        num: [
          {
            required: true,
            message: "最大寄件数:代表单个用户最多可邮寄的[未支付]快递订单数量",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    reset(){
      this.phone=""
    },
    search(){
      this.getlist()
    },
    elswitch(val, row) {
      this.whiteid = row.whiteListId;
      let data = {
        WhiteListId: this.whiteid,
        LimitNumber: row.limitNumber,
        UserName: row.userName,
        phone: row.phone,
        IsStart: val,
        state: 0,
      };
      UpWhiteList(data).then((res) => {
        console.log(res);
        if (res.code > 0) {
          this.$message(res.msg);
          this.getlist();
          this.dialog = false;
        }
      });
    },

    deletes(row) {
      this.whiteid = row.whiteListId;
      let data = {
        WhiteListId: this.whiteid,
        LimitNumber: row.limitNumber,
        UserName: row.userName,
        phone: row.phone,
        State: 1,
        IsDelete: true,
      };
      UpWhiteList(data).then((res) => {
        console.log(res);
        if (res.code > 0) {
          this.$message(res.msg);
          this.getlist();
          this.dialog = false;
        }
      });
    },
    edit(row) {
      this.Dotype = 2;
      this.whiteid = row.whiteListId;
      this.form.name = row.userName;
      this.form.num = row.limitNumber;
      this.dialog = true;
    },
    addcoll() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.Dotype == 1) {
            let data = {
              userName: this.form.name,
              Phone: this.form.phone,
              LimitNumber: Number(this.form.num),
            };
            AddUserWhiteList(data.userName, data.Phone, data.LimitNumber).then(
              (res) => {
                console.log(res);
                if (res.code > 0) {
                  this.$message(res.msg);
                  this.getlist();
                  this.dialog = false;
                }
              }
            );
          } else {
            let data = {
              WhiteListId: this.whiteid,
              limitNumber: this.form.num,
              userName: this.form.name,
              itate: 0,
              isDelete: false,
            };
            UpWhiteList(data).then((res) => {
              console.log(res);
              if (res.code > 0) {
                this.$message(res.msg);
                this.getlist();
                this.dialog = false;
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    btnadd() {
      this.Dotype = 1;
      this.dialog = true;
    },
    btn() {},

    closeDialog(val) {
      console.log("关闭");
      this.form = {
        name: "",
        phone: "",
        num: "",
      };
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.pageSize = val;
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.currentPage = val;
    },

    getlist() {
      let data = {
        Phone: this.phone,
        PageIndex: this.currentPage,
        PageSize: this.pageSize,
      };
      GetUserWhiteListPage(data).then((res) => {
        console.log(res);
        let a = [];
        var reg2 = new RegExp("T");

        res.data.forEach((item) => {
          a.push({ ...item, createTime: computeTime(item.createTime) });
        });
        console.log(a, 11111);
        this.tableData = a;
        this.total = res.total;
      });
    },
  },
  created: function () {
    this.getlist();
  },
};
</script>

<style scope>
.toolbar {
  background: #f2f2f2;
  padding: 10px;
  margin: 10px 0;
}
.divTb {
  margin-top: 20px;
}
.el-pagination {
  text-align: center;
  margin-top: 13px;
}
.el-input {
  width: 300px;
  margin-right: 10px;
}
.right {
  float: right;
  padding-right: 50px;
  padding-bottom: 10px;
}
.spanbtn {
  margin-left: 5px;
}
</style>